// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Layout$SystemaFr from "../components/Layout";
import * as Markdown$SystemaFr from "../components/Markdown";

/* import "../assets/css/ribbon.css" */
import "../assets/css/library.css"
import "../assets/css/components.css"
;

var component = ReasonReact.statelessComponent("ArticleTemplate");

function make(data, _children) {
  var article_title = data.markdownRemark.frontmatter.title;
  var article_content = data.markdownRemark.html;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var onClick = function (_event) {
                window.history.back();
                return /* () */0;
              };
              var backToLibrary = React.createElement("p", {
                    className: "article-nav-links"
                  }, React.createElement("a", {
                        className: "mdl-button mdl-js-button mdl-button--icon mdl-button--accent mdl-js-ripple-effect",
                        href: "#",
                        onClick: onClick
                      }, React.createElement("span", {
                            className: "material-icons md-dark"
                          }, Utils$SystemaFr.text("arrow_back"))), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, React.createElement("a", {
                        href: "#",
                        onClick: onClick
                      }, Utils$SystemaFr.text("Retour")));
              return ReasonReact.element(undefined, undefined, Layout$SystemaFr.make(article_title, "article-layout library-layout", [React.createElement("div", {
                                    className: "page-container mdl-grid"
                                  }, React.createElement("div", {
                                        className: "mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone"
                                      }), React.createElement("div", {
                                        className: "page-content mdl-color--white mdl-shadow--4dp content mdl-color-text--grey-800 mdl-cell mdl-cell--8-col"
                                      }, backToLibrary, React.createElement("article", {
                                            className: "article"
                                          }, ReasonReact.element(undefined, undefined, Markdown$SystemaFr.make(undefined, article_content, []))), React.createElement("br", undefined), backToLibrary))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var $$default = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.data, jsProps.children);
      }));

export {
  component ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
