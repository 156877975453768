// import './index.css'

import ArticleTemplate from '../templates/Article.re'
// import React from 'react'
import { graphql } from 'gatsby'

export default ArticleTemplate

// Markdown query
export const query = graphql`
  query articleById($id: String!) {
    markdownRemark (id: { eq: $id }) {
      id
      frontmatter {
        title
        date
        abstract
        author
        category
      }
      html
    }
  }
`

// Strapi query
// export const query = graphql`
  // query($id: String!) {
    // strapiArticles(id: { eq: $id }) {
      // title
      // category
      // content {
        // childMarkdownRemark {
          // html
        // }
      // }
    // }
  // }
// `
